import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X, ExternalLink } from "lucide-react";
import { createPortal } from "react-dom";
import { Link } from "react-router-dom";

const colors = {
  primaryDark: "#304b78",
  primaryLight: "#435e95",
  secondary: "#2494a2",
  accent: "#f3d678",
  backgroundDark: "#0f172a",
  backgroundLight: "#1e293b",
};

const RegistrationTermsModal = ({ isOpen, onClose }) => {
  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-[100] flex items-center justify-center bg-black/50 backdrop-blur-sm"
          onClick={(e) => {
            if (e.target === e.currentTarget) onClose();
          }}
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="relative w-full max-w-2xl mx-4 p-6 bg-white rounded-xl shadow-xl max-h-[90vh] overflow-y-auto"
          >
            {/* Close button */}
            <button
              onClick={onClose}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              <X className="w-5 h-5" />
            </button>

            {/* Modal content */}
            <div className="mb-6">
              <h2
                className="text-2xl font-bold mb-4"
                style={{ color: colors.primaryDark }}
              >
                Registration T&Cs
              </h2>

              <div className="prose prose-sm max-w-none text-gray-700">
                <p>
                  The following terms apply to all participants in Tour de 4
                  events:
                </p>

                <ul className="list-disc pl-5 space-y-2 mt-4">
                  <li>
                    I declare that the information on this form is complete and
                    correct.
                  </li>
                  <li>
                    I understand and agree that I am responsible for monitoring
                    my own physical condition prior to and during the event,
                    participate in this event at my own risk and that I must
                    rely on my own ability in dealing with all hazards.
                  </li>
                  <li>
                    I will not sell, swap, give away or transfer my place in the
                    event to any other person.
                  </li>
                  <li>
                    I will at all times adhere to instructions given by the
                    event stewards and marshals, and confirm I am aware that the
                    function of the stewards and marshals is only to indicate
                    direction and that I must decide if the movement is safe.
                  </li>
                  <li>
                    I agree that no liability whatsoever shall be attached to
                    the event promoter, organisers, event staff, sponsor(s) or
                    member of the venues in respect of any injury, loss or
                    damage suffered by me on or by reason of the event, (unless
                    caused due to the negligence of the Event Organiser).
                  </li>
                  <li>
                    I understand the Event Organiser will use its reasonable
                    endeavours to offer the Participant their desired wave start
                    time; however, I accept the Event Organiser is not
                    responsible for the availability of any wave start time.
                    Should places for a wave start time be oversubscribed, the
                    Event Organiser reserves the right to allocate the
                    Participant an alternative wave start time.
                  </li>
                  <li>
                    I agree to raise a minimum of £200 for my chosen charity
                    through my dedicated Go Fund Me page.
                  </li>
                  <li>
                    If entering someone under 18 I confirm that I am giving my
                    parental/guardian consent for them to enter and attend this
                    event, and confirm that they are fit, have appropriate
                    equipment and clothing (including any mandatory kit) and are
                    capable of completing the required distance.
                  </li>
                </ul>

                <p className="mt-4">
                  By registering for this event, you also agree to our Website
                  Terms of Use and Privacy Policy. When making an Entry on
                  behalf of another, you confirm:
                </p>

                <ol className="list-decimal pl-5 space-y-2 mt-4">
                  <li>You have obtained their permission to do so;</li>
                  <li>
                    In the case of a child, you are their parent or legal
                    guardian;
                  </li>
                  <li>
                    You have made them aware of the Website Terms of Use and
                    Privacy Policy;
                  </li>
                  <li>
                    You have made them aware of and they have agreed to the
                    Event Disclaimer / Terms and Conditions;
                  </li>
                  <li>
                    They have authorised you to provide their personal data for
                    the purposes of the Event Registration Form;
                  </li>
                  <li>
                    They have consented to the processing by of any Medical Data
                    they may have supplied, including the passing of such data
                    to the Organiser of this Tour de 4.
                  </li>
                </ol>
              </div>

              <div className="mt-8 flex justify-between items-center">
                <Link
                  to="/registration-tcs"
                  className="inline-flex items-center text-sm font-medium"
                  style={{ color: colors.secondary }}
                  onClick={() => {
                    onClose();
                    window.scrollTo(0, 0);
                  }}
                >
                  View full T&Cs <ExternalLink className="ml-1 w-4 h-4" />
                </Link>

                <button
                  onClick={onClose}
                  className="px-4 py-2 rounded-full text-white font-medium"
                  style={{ background: colors.secondary }}
                >
                  Close
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default RegistrationTermsModal;
