import React from "react";
import { Helmet } from "react-helmet-async";

const StructuredData = () => {
  // Base organization data
  const organizationData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "@id": "https://tourde4.com/#organization",
    name: "Tour De 4",
    url: "https://tourde4.com",
    logo: "https://tourde4.com/TD4-logo.svg",
    sameAs: [
      "https://facebook.com/tourde4",
      "https://instagram.com/tourde4",
      "https://twitter.com/tourde4",
    ],
  };

  // Website data
  const websiteData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "@id": "https://tourde4.com/#website",
    name: "Tour De 4",
    url: "https://tourde4.com",
    publisher: {
      "@id": "https://tourde4.com/#organization",
    },
  };

  // Navigation data for sitelinks
  const navigationData = {
    "@context": "https://schema.org",
    "@type": "SiteNavigationElement",
    name: [
      "Home",
      "About",
      "Get Involved",
      "Support",
      "News & Media",
      "Resources",
      "Contact",
    ],
    url: [
      "https://tourde4.com/",
      "https://tourde4.com/about",
      "https://tourde4.com/get-involved",
      "https://tourde4.com/support",
      "https://tourde4.com/news-and-media",
      "https://tourde4.com/resources",
      "https://tourde4.com/contact",
    ],
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(organizationData)}
      </script>
      <script type="application/ld+json">{JSON.stringify(websiteData)}</script>
      <script type="application/ld+json">
        {JSON.stringify(navigationData)}
      </script>
    </Helmet>
  );
};

export default StructuredData;
