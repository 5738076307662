import React, { useEffect } from "react";
import { Calendar, CheckCircle, Bike, Heart, ChevronRight } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { useContent } from "../hooks/useContent";
import { useTab } from "../contexts/TabContext";
import { useLocation } from "react-router-dom";
import { useSeoSettings } from "../hooks/useSeoSettings";
import { Helmet } from "react-helmet-async";

// Custom Card Components
const Card = ({ children, className = "" }) => (
  <div className={`relative rounded-lg p-6 ${className}`}>{children}</div>
);

const CardHeader = ({ children }) => <div className="mb-4">{children}</div>;

const CardTitle = ({ children }) => (
  <h3 className="text-xl font-semibold">{children}</h3>
);

const CardContent = ({ children, className = "" }) => (
  <div className={className}>{children}</div>
);

const DecorativeSection = ({ children, className = "" }) => (
  <div className="relative">
    <div className="absolute inset-0 bg-gradient-to-r from-secondary/20 to-accent/20 rounded-2xl transform -rotate-2" />
    <div className={`relative ${className}`}>{children}</div>
  </div>
);

const Resources = () => {
  const { resourcesTab, setResourcesTab } = useTab();
  const { content, loading } = useContent("resources");
  const location = useLocation();
  const { seoSettings } = useSeoSettings();

  useEffect(() => {
    // Handle tab selection based on URL hash
    const hash = location.hash.slice(1);
    if (hash) {
      setResourcesTab(hash);
    }
  }, [location, setResourcesTab]);

  const renderContent = () => {
    return (
      <AnimatePresence mode="wait">
        {resourcesTab === "preparation" ? (
          <motion.div
            key="preparation"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-12"
          >
            {/* Zwift Training Section */}
            <DecorativeSection>
              <section
                id="zwift-training"
                className="bg-white rounded-xl p-8 shadow-lg"
              >
                <div className="flex flex-col md:flex-row md:items-center mb-6">
                  <div className="flex items-center mb-4 md:mb-0 md:mr-4">
                    <img
                      src="/sponsors/zwift.png"
                      alt="Zwift Logo"
                      className="h-12 w-auto"
                    />
                  </div>
                  <div>
                    <h2 className="text-3xl font-bold">Training with Zwift</h2>
                    <p className="text-gray-600 mt-2">
                      Join our official partner Zwift for structured training
                      sessions designed specifically for Tour De 4 participants.
                    </p>
                  </div>
                </div>
                <a
                  href="https://www.zwift.com/uk/events/series/tour-de-4"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block mb-8 px-6 py-3 bg-secondary text-white rounded-lg hover:bg-secondary/90 transition-colors"
                >
                  Sign Up for Zwift Training Sessions
                </a>

                <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                  {loading ? (
                    <div className="col-span-full text-center py-8">
                      Loading training dates...
                    </div>
                  ) : (
                    content?.trainingDates?.map((session, index) => (
                      <Card key={index} className="bg-gray-50">
                        <CardContent className="pt-6">
                          <div className="flex flex-col space-y-3">
                            <div className="flex items-start space-x-4">
                              <Calendar className="w-5 h-5 text-secondary flex-shrink-0" />
                              <div>
                                <p className="font-semibold">{session.date}</p>
                                <p className="text-sm text-gray-600">
                                  {session.time}
                                </p>
                              </div>
                            </div>
                            <div className="flex items-start space-x-4">
                              <Bike className="w-5 h-5 text-secondary flex-shrink-0" />
                              <div>
                                <p className="text-sm font-medium text-secondary">
                                  {session.type}
                                </p>
                                <p className="text-sm text-gray-600">
                                  Route: {session.route}
                                </p>
                                <p className="text-sm text-gray-600">
                                  Duration: {session.duration}
                                </p>
                              </div>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    ))
                  )}
                </div>
              </section>
            </DecorativeSection>

            {/* Resources Section */}
            <section
              id="resources"
              className="bg-gradient-to-br from-background-light to-background-dark text-white rounded-xl p-8"
            >
              <h2 className="text-3xl font-bold mb-6">
                What To Expect From Registration
              </h2>
              <div className="grid md:grid-cols-3 gap-8">
                {[
                  {
                    title: "Equipment Checklist",
                    icon: Bike,
                    items: [
                      "Bike maintenance",
                      "Required gear",
                      "Recommended spares",
                    ],
                  },
                  {
                    title: "Nutrition Guide",
                    icon: Heart,
                    items: [
                      "Pre-ride meals",
                      "During ride nutrition",
                      "Recovery foods",
                    ],
                  },
                  {
                    title: "Safety Guidelines",
                    icon: CheckCircle,
                    items: [
                      "Road safety rules",
                      "Emergency contacts",
                      "Weather preparation",
                    ],
                  },
                ].map((resource, index) => (
                  <Card key={index} className="bg-white/10 backdrop-blur">
                    <CardHeader>
                      <resource.icon className="w-8 h-8 text-accent mb-2" />
                      <CardTitle className="text-xl text-white">
                        {resource.title}
                      </CardTitle>
                    </CardHeader>
                    <CardContent>
                      <ul className="space-y-3">
                        {resource.items.map((item, i) => (
                          <li key={i} className="flex items-center">
                            <CheckCircle className="w-4 h-4 mr-2 text-accent" />
                            <span className="text-white/90">{item}</span>
                          </li>
                        ))}
                      </ul>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </section>
          </motion.div>
        ) : resourcesTab === "travel" ? (
          <motion.div
            key="travel"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-12"
          >
            {/* Venue Information Section */}
            <DecorativeSection>
              <section id="venue" className="bg-white rounded-xl p-8 shadow-lg">
                <h2 className="text-3xl font-bold mb-6 text-secondary">
                  Venue Information
                </h2>
                <div className="grid md:grid-cols-2 gap-8">
                  <div>
                    <h3 className="text-xl font-semibold mb-4">
                      Sir Chris Hoy Velodrome
                    </h3>
                    <div className="space-y-2">
                      <p className="text-gray-600">1000 London Road</p>
                      <p className="text-gray-600">Glasgow</p>
                      <p className="text-gray-600">G40 3HG</p>
                      <p className="mt-4">
                        <span className="font-medium">what3words:</span>{" "}
                        <a
                          href="https://what3words.com/storms.spaces.flash"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-secondary hover:text-secondary/80"
                        >
                          ///storms.spaces.flash
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </DecorativeSection>

            {/* Transport Section */}
            <section className="bg-gradient-to-br from-background-light to-background-dark text-white rounded-xl p-8">
              <h2 className="text-3xl font-bold mb-6">Getting There</h2>
              <div className="grid md:grid-cols-2 gap-8">
                {/* Cycling Routes */}
                <div>
                  <h3 className="text-xl font-semibold mb-4 flex items-center">
                    <Bike className="w-6 h-6 mr-2 text-accent" />
                    Cycling Routes
                  </h3>
                  <p className="text-white/90 mb-4">
                    Cycling routes from Glasgow City Centre to the Sir Chris Hoy
                    Velodrome are both convenient and efficient, typically
                    taking around 15-20 minutes. The recommended route is via
                    the East City Way, a dedicated cycle path that provides a
                    safer connection between the city centre and the Emirates
                    Arena.
                  </p>
                  <div className="space-y-2">
                    <a
                      href="https://www.glasgow.gov.uk/article/3948/East-City-Way"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block text-accent hover:text-accent/80"
                    >
                      East City Way Information →
                    </a>
                    <a
                      href="https://www.sustrans.org.uk/find-a-route-on-the-national-cycle-network/?location=Scotland"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block text-accent hover:text-accent/80"
                    >
                      Sustrans Route Planner →
                    </a>
                  </div>
                </div>

                {/* Public Transport */}
                <div>
                  <h3 className="text-xl font-semibold mb-4">
                    Public Transport
                  </h3>
                  <div className="space-y-4">
                    <div>
                      <h4 className="font-medium mb-2">Train Stations</h4>
                      <ul className="space-y-2 text-white/90">
                        <li>• Nearest station: Dalmarnock Station</li>
                        <li>• Glasgow Central Station (15-20 min cycle)</li>
                        <li>
                          • Glasgow Queen Street Station (15-20 min cycle)
                        </li>
                      </ul>
                      <a
                        href="https://www.scotrail.co.uk"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block mt-2 text-accent hover:text-accent/80"
                      >
                        Check ScotRail Timetables →
                      </a>
                    </div>
                    <div>
                      <h4 className="font-medium mb-2">Local Bus Services</h4>
                      <ul className="space-y-1 text-white/90">
                        <li>• Service 18</li>
                        <li>• Service 64</li>
                        <li>• Service 240</li>
                      </ul>
                      <a
                        href="https://www.firstbus.co.uk/greater-glasgow/routes-and-maps/route-maps"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block mt-2 text-accent hover:text-accent/80"
                      >
                        View Bus Timetables →
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Accommodation Section */}
            <DecorativeSection>
              <section
                id="accommodation"
                className="bg-white rounded-xl p-8 shadow-lg"
              >
                <h2 className="text-3xl font-bold mb-6 text-secondary">
                  Accommodation
                </h2>
                <p className="text-gray-600 mb-6">
                  Glasgow City Centre offers a diverse range of accommodation to
                  suit various preferences and budgets. Here are some
                  recommended options near the Emirates Arena:
                </p>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
                  {[
                    {
                      name: "Voco Grand Central Glasgow",
                      website: "https://grandcentral.vocohotels.com/",
                      description:
                        "One of the City's most Prestigious buildings",
                      distance: "2.3 miles",
                      location: "Located next to Glasgow Central Station",
                      walkTime: "40 minutes",
                      driveTime: "10-15 minutes",
                    },
                    {
                      name: "Kimpton Blythswood Square Hotel",
                      website: "https://www.kimptonblythswoodsquare.com/",
                      description: "Luxury hotel in Glasgow city centre",
                      distance: "2.5 miles",
                      location:
                        "Located in the city center west of the velodrome",
                      walkTime: "45 minutes",
                      driveTime: "12-15 minutes",
                    },
                    {
                      name: "Apex City of Glasgow Hotel",
                      website:
                        "https://www.apexhotels.co.uk/destinations/glasgow/apex-city-of-glasgow-hotel/",
                      description: "Contemporary hotel in prime location",
                      distance: "2.4 miles",
                      location: "Located on Bath Street in the city center",
                      walkTime: "45 minutes",
                      driveTime: "12-15 minutes",
                    },
                    {
                      name: "Premier Inn Glasgow City Centre",
                      website:
                        "https://www.premierinn.com/gb/en/hotels/scotland/strathclyde/glasgow/glasgow-city-centre-george-square.html",
                      description:
                        "Affordable accommodation near George Square",
                      distance: "2.1 miles",
                      location: "Located near George Square",
                      walkTime: "35-40 minutes",
                      driveTime: "10-12 minutes",
                      note: "One of the closest hotels to the velodrome",
                    },
                    {
                      name: "Ibis Glasgow City Centre",
                      website: "https://all.accor.com/",
                      description: "Part of the Accor Hotels group",
                      distance: "2.6 miles",
                      location: "Located on Sauchiehall Street",
                      walkTime: "45-50 minutes",
                      driveTime: "12-15 minutes",
                    },
                    {
                      name: "The Z Hotel Glasgow",
                      website: "https://www.thezhotels.com/hotels/glasgow/",
                      description: "Compact luxury in the city centre",
                      distance: "2.2 miles",
                      location: "Located near George Square",
                      walkTime: "40 minutes",
                      driveTime: "10-12 minutes",
                      note: "One of the closest hotels to the velodrome",
                    },
                  ].map((hotel, index) => (
                    <a
                      key={index}
                      href={hotel.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group p-6 bg-gray-50 rounded-lg border border-gray-200 hover:bg-gray-100 hover:border-secondary/20 transition-all duration-200"
                    >
                      <div className="space-y-3">
                        <div className="flex items-start justify-between">
                          <h3 className="font-medium text-gray-900 group-hover:text-secondary">
                            {hotel.name}
                          </h3>
                          <ChevronRight className="w-5 h-5 text-gray-400 group-hover:text-secondary transform group-hover:translate-x-1 transition-transform" />
                        </div>
                        <div className="space-y-2">
                          <p className="text-sm text-gray-600">
                            {hotel.description}
                          </p>
                          <p className="text-sm text-gray-600">
                            {hotel.location}
                          </p>
                          <div className="pt-2 space-y-1">
                            <p className="text-sm">
                              <span className="font-medium">Distance:</span>{" "}
                              <span className="text-gray-600">
                                {hotel.distance}
                              </span>
                            </p>
                            <p className="text-sm">
                              <span className="font-medium">Walking:</span>{" "}
                              <span className="text-gray-600">
                                {hotel.walkTime}
                              </span>
                            </p>
                            <p className="text-sm">
                              <span className="font-medium">Driving:</span>{" "}
                              <span className="text-gray-600">
                                {hotel.driveTime}
                              </span>
                            </p>
                          </div>
                          {hotel.note && (
                            <p className="text-sm text-secondary/90 italic">
                              {hotel.note}
                            </p>
                          )}
                        </div>
                        <p className="text-sm text-secondary group-hover:underline pt-2">
                          Visit website
                        </p>
                      </div>
                    </a>
                  ))}
                </div>
                <div className="space-y-4">
                  <div className="p-4 bg-secondary/10 rounded-lg space-y-3">
                    <p className="text-gray-700 font-medium">
                      Travel Options to Velodrome:
                    </p>
                    <ul className="text-gray-600 space-y-2 text-sm">
                      <li>
                        • <span className="font-medium">Taxi/car:</span> 10-15
                        minutes from city center
                      </li>
                      <li>
                        • <span className="font-medium">Bus:</span> Several
                        routes available from city center
                      </li>
                      <li>
                        • <span className="font-medium">Train:</span> Nearest
                        station is Dalmarnock (5-minute walk to the velodrome)
                      </li>
                      <li>
                        • <span className="font-medium">Walking:</span> 35-50
                        minutes depending on starting point
                      </li>
                    </ul>
                  </div>
                  <div className="mt-6 p-4 bg-secondary/10 rounded-lg">
                    <p className="text-gray-700">
                      <strong>Note:</strong> We recommend contacting your
                      accommodation provider ahead of time regarding bike
                      storage and parking facilities.
                    </p>
                  </div>
                </div>
              </section>
            </DecorativeSection>

            {/* Things to Do Section */}
            <section className="bg-white rounded-xl p-8 shadow-lg">
              <h2 className="text-3xl font-bold mb-8 text-secondary">
                Explore Glasgow
              </h2>
              <div className="space-y-8">
                {/* Cultural Hotspots */}
                <div className="grid md:grid-cols-2 gap-8 items-center">
                  <div className="space-y-4">
                    <h3 className="text-xl font-semibold">Cultural Hotspots</h3>
                    <p className="text-gray-600">
                      Don't miss iconic museums and galleries such as
                      Kelvingrove Museum and Riverside Museum. Glasgow's rich
                      cultural heritage is on display through its world-class
                      museums and galleries.
                    </p>
                    <a
                      href="https://www.visitglasgow.com/see-do/attractions/museums-in-glasgow"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center text-secondary hover:text-secondary/80 group"
                    >
                      Discover Museums
                      <ChevronRight className="w-4 h-4 ml-1 transform group-hover:translate-x-1 transition-transform" />
                    </a>
                  </div>
                  <div className="relative h-64 rounded-xl overflow-hidden">
                    <img
                      src="/optimized/kelvingrove museum.webp"
                      alt="Kelvingrove Art Gallery and Museum"
                      className="w-full h-full object-cover"
                      loading="lazy"
                    />
                  </div>
                </div>

                {/* Music Scene */}
                <div className="grid md:grid-cols-2 gap-8 items-center">
                  <div className="order-2 md:order-1 relative h-64 rounded-xl overflow-hidden">
                    <img
                      src="/optimized/barrowland.webp"
                      alt="The Barrowlands Music Venue"
                      className="w-full h-full object-cover"
                      loading="lazy"
                    />
                  </div>
                  <div className="order-1 md:order-2 space-y-4">
                    <h3 className="text-xl font-semibold">Music Scene</h3>
                    <p className="text-gray-600">
                      Glasgow's legendary music scene comes alive at iconic
                      venues like King Tut's, SWG3, and the historic
                      Barrowlands. Experience live music in some of Scotland's
                      most famous venues.
                    </p>
                    <a
                      href="https://www.gigsinscotland.com/gigs/by-date"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center text-secondary hover:text-secondary/80 group"
                    >
                      Find Gigs
                      <ChevronRight className="w-4 h-4 ml-1 transform group-hover:translate-x-1 transition-transform" />
                    </a>
                  </div>
                </div>

                {/* Street Art */}
                <div className="grid md:grid-cols-2 gap-8 items-center">
                  <div className="space-y-4">
                    <h3 className="text-xl font-semibold">Street Art</h3>
                    <p className="text-gray-600">
                      Explore Glasgow's iconic Mural Trail, a vibrant part of
                      the city's art scene. Discover massive works of art that
                      transform the city's buildings into stunning canvases.
                    </p>
                    <a
                      href="https://www.citycentremuraltrail.co.uk"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center text-secondary hover:text-secondary/80 group"
                    >
                      View Mural Trail
                      <ChevronRight className="w-4 h-4 ml-1 transform group-hover:translate-x-1 transition-transform" />
                    </a>
                  </div>
                  <div className="relative h-64 rounded-xl overflow-hidden">
                    <img
                      src="/optimized/glasgow-mural-trail.webp"
                      alt="Glasgow Street Art Mural Trail"
                      className="w-full h-full object-cover"
                      loading="lazy"
                    />
                  </div>
                </div>

                {/* Parks & Green Spaces */}
                <div className="grid md:grid-cols-2 gap-8 items-center">
                  <div className="order-2 md:order-1 relative h-64 rounded-xl overflow-hidden">
                    <img
                      src="/optimized/kelvingrove park.webp"
                      alt="Kelvingrove Park"
                      className="w-full h-full object-cover"
                      loading="lazy"
                    />
                  </div>
                  <div className="order-1 md:order-2 space-y-4">
                    <h3 className="text-xl font-semibold">
                      Parks & Green Spaces
                    </h3>
                    <p className="text-gray-600">
                      Perfect for a warm-up cycle before Tour de 4! Visit the
                      beautiful Kelvingrove Park and historic Glasgow Green,
                      offering scenic routes and peaceful spaces in the heart of
                      the city.
                    </p>
                    <a
                      href="https://www.glasgow.gov.uk/parks"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center text-secondary hover:text-secondary/80 group"
                    >
                      Explore Parks
                      <ChevronRight className="w-4 h-4 ml-1 transform group-hover:translate-x-1 transition-transform" />
                    </a>
                  </div>
                </div>

                <div className="pt-4">
                  <a
                    href="https://www.visitscotland.com/places-to-go/glasgow/things-to-do"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block w-full text-center px-6 py-3 bg-secondary text-white rounded-lg hover:bg-secondary/90 transition-colors"
                  >
                    Discover More Things to Do in Glasgow →
                  </a>
                </div>
              </div>
            </section>
          </motion.div>
        ) : null}
      </AnimatePresence>
    );
  };

  // Update the tab navigation section
  const tabs = [
    { id: "preparation", label: "Preparation" },
    { id: "travel", label: "Travel & Accommodation" },
  ];

  if (loading) {
    return (
      <div className="min-h-screen bg-slate-800 flex items-center justify-center">
        <div className="text-white">Loading...</div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {seoSettings?.pages?.resources?.title || "Resources - Tour De 4"}
        </title>
        <meta
          name="description"
          content={
            seoSettings?.pages?.resources?.description ||
            "Access helpful resources and information about Stage 4 cancer, cycling, and support services."
          }
        />
        <meta name="theme-color" content={seoSettings?.metaTags?.themeColor} />

        {/* Standard Meta Image */}
        <meta name="image" content="/TD4-logo.webp" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.tourde4.com" />
        <meta
          property="og:title"
          content={
            seoSettings?.pages?.resources?.title || "Resources - Tour De 4"
          }
        />
        <meta
          property="og:description"
          content={
            seoSettings?.pages?.resources?.description ||
            "Access helpful resources and information about Stage 4 cancer, cycling, and support services."
          }
        />
        <meta property="og:image" content="/TD4-logo.webp" />
        <meta property="og:image:type" content="image/webp" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://www.tourde4.com" />
        <meta
          name="twitter:title"
          content={
            seoSettings?.pages?.resources?.title || "Resources - Tour De 4"
          }
        />
        <meta
          name="twitter:description"
          content={
            seoSettings?.pages?.resources?.description ||
            "Access helpful resources and information about Stage 4 cancer, cycling, and support services."
          }
        />
        <meta name="twitter:image" content="/TD4-logo.webp" />
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="min-h-screen bg-white"
      >
        {/* Hero Section */}
        <div className="relative bg-[#0f172a]">
          <div className="absolute inset-0 bg-gradient-to-r from-secondary/20 to-accent/20 opacity-50" />
          <div className="max-w-4xl mx-auto px-4 py-24 md:py-32">
            <h1 className="text-5xl md:text-6xl font-bold text-white mb-6">
              Resources
            </h1>
            <p className="text-lg sm:text-xl md:text-2xl text-white/80 max-w-3xl">
              Everything you need to prepare for and participate in Tour De 4
            </p>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-7xl mx-auto px-4 py-12">
          {/* Tab Navigation */}
          <div
            id="resources-nav"
            className="bg-white sticky top-0 z-10 mb-12 rounded-lg overflow-hidden"
          >
            <div className="max-w-7xl mx-auto px-6">
              <div className="flex space-x-8 -mb-px overflow-x-auto no-scrollbar">
                {tabs.map((tab) => (
                  <button
                    key={tab.id}
                    ref={(el) => {
                      // Store reference to button element
                      if (el && resourcesTab === tab.id) {
                        setTimeout(() => {
                          el.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                            inline: "center",
                          });
                        }, 100);
                      }
                    }}
                    onClick={(e) => {
                      setResourcesTab(tab.id);
                      window.history.pushState(null, "", `#${tab.id}`);
                      const element = document.getElementById(tab.id);
                      element?.scrollIntoView({ behavior: "smooth" });
                      // Scroll the tab into view
                      e.currentTarget.scrollIntoView({
                        behavior: "smooth",
                        block: "nearest",
                        inline: "center",
                      });
                    }}
                    className={`py-4 px-1 border-b-2 font-medium text-lg transition-colors whitespace-nowrap flex-shrink-0 ${
                      resourcesTab === tab.id
                        ? "border-secondary text-secondary"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                    }`}
                  >
                    {tab.label}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Content Sections */}
          {renderContent()}
        </div>
      </motion.div>
    </>
  );
};

// Add this CSS at the top of the file after the imports
const style = document.createElement("style");
style.textContent = `
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-x: hidden;
    scrollbar-width: none;
  }
`;
document.head.appendChild(style);

export default Resources;
