import React, { useEffect, useRef, useState } from "react";
import { useContent } from "../hooks/useContent";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ExternalLink } from "lucide-react";
import RegistrationTermsModal from "./RegistrationTermsModal";

const EventbriteWidget = ({
  eventId,
  buttonText = "Register Now",
  buttonClassName = "",
  style = {},
  onRegisterInterest = () => {},
  showTermsLink = true,
  isSoldOut = false,
  soldOutText = "This Wave Sold Out",
}) => {
  const buttonRef = useRef(null);
  const modalTriggerId = `eventbrite-widget-modal-trigger-${eventId}`;
  const widgetInitialized = useRef(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const { content: siteConfig } = useContent("siteConfig");
  const navigate = useNavigate();
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  // Check if registration modal is enabled in site config
  const isRegistrationModalEnabled = !siteConfig?.disableRegistrationModal;

  // Check if Eventbrite script is loaded
  useEffect(() => {
    // Only proceed with Eventbrite widget if registration modal is disabled and not sold out
    if (isRegistrationModalEnabled || isSoldOut) {
      return;
    }

    if (window.EBWidgets) {
      setScriptLoaded(true);
    } else {
      // Set up a MutationObserver to watch for the script being added
      const observer = new MutationObserver((mutations) => {
        if (window.EBWidgets) {
          setScriptLoaded(true);
          observer.disconnect();
        }
      });

      observer.observe(document.head, { childList: true, subtree: true });

      // Also check after a delay in case we missed it
      const timeoutId = setTimeout(() => {
        if (window.EBWidgets) {
          setScriptLoaded(true);
        }
      }, 2000);

      return () => {
        observer.disconnect();
        clearTimeout(timeoutId);
      };
    }
  }, [isRegistrationModalEnabled, isSoldOut]);

  // Initialize the widget when the script is loaded
  useEffect(() => {
    // Only proceed with Eventbrite widget if registration modal is disabled and not sold out
    if (isRegistrationModalEnabled || isSoldOut) {
      return;
    }

    if (scriptLoaded && buttonRef.current && !widgetInitialized.current) {
      try {
        window.EBWidgets.createWidget({
          widgetType: "checkout",
          eventId: eventId,
          modal: true,
          modalTriggerElementId: modalTriggerId,
          onOrderComplete: () => {
            console.log("Order complete!");
          },
        });
        widgetInitialized.current = true;
      } catch (error) {
        console.error("Error initializing Eventbrite widget:", error);
      }
    }
  }, [
    scriptLoaded,
    eventId,
    modalTriggerId,
    isRegistrationModalEnabled,
    isSoldOut,
  ]);

  // Handle button click
  const handleClick = () => {
    // Don't proceed if sold out
    if (isSoldOut) {
      return;
    }

    // If registration modal is enabled, show it instead of Eventbrite widget
    if (isRegistrationModalEnabled) {
      onRegisterInterest();
      return;
    }

    // If widget failed to initialize, navigate to registration page
    if (!widgetInitialized.current) {
      navigate("/get-involved#registration");
    }
  };

  // Handle terms link click
  const handleTermsClick = (e) => {
    e.preventDefault();
    setIsTermsModalOpen(true);
  };

  // Apply sold out styles
  const getSoldOutStyles = () => {
    if (!isSoldOut) return style;
    return {
      ...style,
      background: "#9ca3af", // Grey color
      cursor: "not-allowed",
      opacity: 0.7,
    };
  };

  // Apply sold out class names
  const getSoldOutClassNames = () => {
    if (!isSoldOut) return buttonClassName;
    return `${buttonClassName} opacity-70 cursor-not-allowed`;
  };

  return (
    <div className="flex flex-col items-center">
      {/* Noscript fallback for SEO */}
      <noscript>
        <a
          href={`https://www.eventbrite.com/e/tickets-${eventId}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {isSoldOut ? soldOutText : buttonText}
        </a>
      </noscript>

      {/* Actual button that triggers the widget */}
      <button
        id={modalTriggerId}
        ref={buttonRef}
        type="button"
        className={getSoldOutClassNames()}
        style={getSoldOutStyles()}
        onClick={handleClick}
        disabled={isSoldOut}
      >
        {isSoldOut ? soldOutText : buttonText}
      </button>

      {/* Terms link */}
      {showTermsLink && !isSoldOut && (
        <button
          onClick={handleTermsClick}
          className="mt-2 text-xs text-gray-500 hover:text-gray-700 flex flex-col items-center text-center"
        >
          <span>By registering, you accept our</span>
          <span className="flex items-center">
            <span className="underline mx-1">registration T&Cs</span>
            <ExternalLink className="w-3 h-3" />
          </span>
        </button>
      )}

      {/* Terms Modal */}
      <RegistrationTermsModal
        isOpen={isTermsModalOpen}
        onClose={() => setIsTermsModalOpen(false)}
      />
    </div>
  );
};

export default EventbriteWidget;
