// src/scripts/initializeSeoSettings.js
import { db } from "../firebase";
import { doc, setDoc } from "firebase/firestore";

const defaultSeoSettings = {
  // Global SEO settings
  metaTags: {
    title: "Tour De 4 - A Fundraising Cycle Event with Sir Chris Hoy",
    description:
      "Join Sir Chris Hoy's fundraising charity bike ride aimed at shining a spotlight on Stage 4 cancer diagnosis and demonstrating that it's possible to live well alongside this devastating diagnosis.",
    themeColor: "#0f172a",
  },
  openGraph: {
    title: "Tour De 4 - A Fundraising Cycle Event with Sir Chris Hoy",
    description:
      "Join Sir Chris Hoy's fundraising charity bike ride aimed at shining a spotlight on Stage 4 cancer diagnosis and demonstrating that it's possible to live well alongside this devastating diagnosis.",
    url: "https://tourde4.com/",
    image: "/TD4-logo.webp",
  },
  twitter: {
    title: "Tour De 4 - A Fundraising Cycle Event with Sir Chris Hoy",
    description:
      "Join Sir Chris Hoy's fundraising charity bike ride aimed at shining a spotlight on Stage 4 cancer diagnosis and demonstrating that it's possible to live well alongside this devastating diagnosis.",
    image: "/TD4-logo.webp",
    card: "summary_large_image",
  },
  googleAnalytics: {
    id: "G-7KERDK7LB0",
    tagManagerId: "GT-5GK56F4K",
  },
  // Page-specific SEO settings
  pages: {
    home: {
      title: "Tour De 4 - Join Sir Chris Hoy's Charity Bike Ride",
      description:
        "Be part of Sir Chris Hoy's groundbreaking charity bike ride, raising awareness and funds for Stage 4 cancer research and support.",
      image: "/td4-logo.jpg",
    },
    about: {
      title: "About Tour De 4 - Our Mission & Vision",
      description:
        "Learn about Tour De 4's mission to support those living with Stage 4 cancer and our vision for creating positive change through cycling.",
      image: "/td4-logo.jpg",
    },
    getInvolved: {
      title: "Get Involved with Tour De 4 - Join the Movement",
      description:
        "Discover how you can participate in Tour De 4, whether you're living with Stage 4 cancer, supporting someone who is, or want to make a difference.",
      image: "/td4-logo.jpg",
    },
    support: {
      title: "Support Tour De 4 - Make a Difference",
      description:
        "Support Tour De 4's mission to raise awareness and funds for Stage 4 cancer research and support services.",
      image: "/td4-logo.jpg",
    },
    newsAndMedia: {
      title: "News & Media - Tour De 4",
      description:
        "Stay updated with the latest news, events, and media coverage from Tour De 4, a charity bike ride initiative by Sir Chris Hoy.",
      image: "/td4-logo.jpg",
    },
    contact: {
      title: "Contact Tour De 4 - Get in Touch",
      description:
        "Contact the Tour De 4 team for inquiries about participation, support, or media coverage.",
      image: "/td4-logo.jpg",
    },
    resources: {
      title: "Resources - Tour De 4",
      description:
        "Access helpful resources and information about Stage 4 cancer, cycling, and support services.",
      image: "/td4-logo.jpg",
    },
    donate: {
      title: "Donate to Tour De 4 - Support Our Cause",
      description:
        "Make a donation to support Tour De 4's mission in raising awareness and funds for Stage 4 cancer research and support.",
      image: "/td4-logo.jpg",
    },
    privacy: {
      title: "Privacy Policy - Tour De 4",
      description:
        "Read about Tour De 4's privacy policy and how we protect your personal information.",
      image: "/td4-logo.jpg",
    },
    terms: {
      title: "Terms of Service - Tour De 4",
      description:
        "Review Tour De 4's terms of service and conditions for using our website and services.",
      image: "/td4-logo.jpg",
    },
    cookies: {
      title: "Cookie Policy - Tour De 4",
      description:
        "Learn about how Tour De 4 uses cookies and how you can manage your cookie preferences.",
      image: "/td4-logo.jpg",
    },
  },
};

export const initialiseSeoSettings = async () => {
  try {
    await setDoc(doc(db, "settings", "seo"), defaultSeoSettings);
    console.log("SEO settings initialized successfully");
    return true;
  } catch (error) {
    console.error("Error initializing SEO settings:", error);
    return false;
  }
};

// Make the function accessible globally for initialization from the console
if (typeof window !== "undefined") {
  window.initializeSeoSettings = initialiseSeoSettings;
}
