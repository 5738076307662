import React, { createContext, useContext } from "react";
import { useContent } from "../hooks/useContent";

const ContentContext = createContext(null);

export const ContentProvider = ({ children }) => {
  const contentState = useContent();

  // If we're loading the initial content, we can choose to show a loading state
  // or just render children since individual components handle their own loading states
  return (
    <ContentContext.Provider value={contentState}>
      {children}
    </ContentContext.Provider>
  );
};

export const useContentContext = () => {
  const context = useContext(ContentContext);
  if (!context) {
    throw new Error("useContentContext must be used within a ContentProvider");
  }
  return context;
};
