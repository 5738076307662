import { useState, useEffect, useCallback } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { logger } from "../utils/logger";

const CACHE_TTL = {
  LONG: 1000 * 60 * 60 * 24, // 24 hours for static content
  MEDIUM: 1000 * 60 * 60, // 1 hour for semi-dynamic content
  SHORT: 1000 * 60 * 5, // 5 minutes for frequently updated content
};

const getCacheTTL = (contentType) => {
  switch (contentType) {
    case "homepage":
      return CACHE_TTL.LONG;
    case "about":
      return CACHE_TTL.SHORT;
    case "support":
      return CACHE_TTL.MEDIUM;
    case "getInvolved":
      return CACHE_TTL.SHORT;
    case "newsAndMedia":
      return CACHE_TTL.SHORT;
    default:
      return CACHE_TTL.MEDIUM;
  }
};

const getInitialState = (contentType) => {
  switch (contentType) {
    case "newsAndMedia":
      return {
        hero: {
          title: "",
          subtitle: "",
        },
        newsItems: [],
        mediaItems: [],
      };
    case "homepage":
      return {
        hero: {
          title: "",
          subtitle: "",
          backgroundImage: "",
          ctaText: "",
        },
        intro: {
          title: "",
          description: "",
          image: "",
          ctaText: "",
        },
        facts: {
          title: "",
          items: [],
        },
        registration: {
          title: "",
          description: "",
          registerButtonText: "",
          sponsorButtonText: "",
        },
        sponsors: {
          title: "",
          description: "",
          items: [],
        },
        social: {
          title: "",
          facebook: "",
          twitter: "",
          instagram: "",
        },
      };
    case "siteConfig":
      return {
        disableRegistrationModal: false,
        defaultRegistrationUrl: "",
        modalContent: {
          title: "Register Your Interest",
          subtitle: "Stay updated with the latest news and developments.",
          infoText: "Registrations will open on 28th February, 2025",
          buttonText: "Register Interest",
        },
        modalSchedule: {
          enabled: false,
          startDateTime: "",
          endDateTime: "",
        },
      };
    case "support":
      return {
        sponsors: [],
        charities: [],
        distribution: [],
        contact: {
          title: "",
          description: "",
          email: "",
          buttonText: "",
        },
      };
    case "getInvolved":
      return {
        hero: {
          title: "Get Involved",
          description:
            "Join us in making a difference in the fight against Stage 4 cancer. Whether you're a seasoned cyclist or just starting out, there's a way for you to participate and contribute to this meaningful cause.",
        },
        dates: {
          registrationDeadline: "",
          trainingStart: "",
          eventDay: "",
        },
        fundraising: {
          target: "",
          participantTarget: "",
          impactStatement: "",
          tips: [],
          goals: [],
        },
        whatsIncluded: {
          title: "What's Included in Your Registration",
          items: [],
        },
        images: {
          hero: "",
          registration: "",
          fundraising1: "",
          fundraising2: "",
        },
      };
    case "landingPage":
    default:
      return {
        hero: {
          title: "",
          subtitle: "",
        },
        mainContent: {
          imageSrc: "/Peligoni-10.webp",
          paragraph1: "",
          paragraph2: "",
          quote: "",
        },
      };
  }
};

export const useContent = (contentType = "landingPage") => {
  const [content, setContent] = useState(() => {
    const cached = localStorage.getItem(`${contentType}Content`);
    if (cached) {
      try {
        const { data, timestamp } = JSON.parse(cached);
        if (Date.now() - timestamp < getCacheTTL(contentType)) {
          logger.info(
            `[useContent] Using cached content for ${contentType}:`,
            data
          );
          return data;
        }
      } catch (error) {
        logger.error("[useContent] Cache parse error:", error);
      }
    }
    return getInitialState(contentType);
  });

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);

  const loadContent = useCallback(
    async (force = false) => {
      const cached = localStorage.getItem(`${contentType}Content`);
      if (!force && cached) {
        try {
          const { data, timestamp } = JSON.parse(cached);
          const ttl = getCacheTTL(contentType);
          if (Date.now() - timestamp < ttl) {
            logger.info(
              `[useContent] Using cached content for ${contentType}:`,
              data
            );
            setContent(data);
            setLoading(false);
            setLastUpdated(timestamp);
            return;
          }
        } catch (error) {
          logger.error("[useContent] Cache parse error:", error);
        }
      }

      setLoading(true);

      try {
        const docRef = doc(db, "content", contentType);
        logger.info(`[useContent] Fetching from Firestore for ${contentType}`);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const newContent = docSnap.data();
          logger.info(`[useContent] Firestore data received:`, newContent);

          // Update state and cache
          setContent(newContent);
          const timestamp = Date.now();
          setLastUpdated(timestamp);

          localStorage.setItem(
            `${contentType}Content`,
            JSON.stringify({
              data: newContent,
              timestamp,
            })
          );
        } else {
          logger.info(
            `[useContent] No data in Firestore for ${contentType}, using initial state`
          );
          const initialState = getInitialState(contentType);
          setContent(initialState);
          const timestamp = Date.now();
          setLastUpdated(timestamp);

          localStorage.setItem(
            `${contentType}Content`,
            JSON.stringify({
              data: initialState,
              timestamp,
            })
          );
        }
      } catch (err) {
        logger.error(`[useContent] Error loading content:`, err);
        setError(err.message);

        // On error, try to use cached content regardless of TTL
        if (cached) {
          try {
            const { data } = JSON.parse(cached);
            setContent(data);
          } catch (cacheError) {
            setContent(getInitialState(contentType));
          }
        } else {
          setContent(getInitialState(contentType));
        }
      } finally {
        setLoading(false);
      }
    },
    [contentType]
  );

  useEffect(() => {
    loadContent();

    // Set up periodic background refresh for frequently updated content
    let refreshInterval;
    if (contentType === "newsAndMedia") {
      refreshInterval = setInterval(() => {
        loadContent(true);
      }, CACHE_TTL.SHORT);
    }

    return () => {
      if (refreshInterval) {
        clearInterval(refreshInterval);
      }
      setContent(getInitialState(contentType));
      setLoading(true);
      setError(null);
    };
  }, [contentType, loadContent]);

  const saveContent = useCallback(
    async (newContent) => {
      setSaving(true);
      setError(null);

      try {
        logger.info(`[useContent] Saving content to Firestore:`, {
          contentType,
          content: newContent,
        });

        const docRef = doc(db, "content", contentType);
        await setDoc(docRef, newContent);

        // Update state and cache
        setContent(newContent);
        const timestamp = Date.now();
        setLastUpdated(timestamp);

        localStorage.setItem(
          `${contentType}Content`,
          JSON.stringify({
            data: newContent,
            timestamp,
          })
        );

        logger.info(`[useContent] Content saved successfully`);
        return true;
      } catch (err) {
        logger.error(`[useContent] Error saving content:`, err);
        setError(err.message);
        return false;
      } finally {
        setSaving(false);
      }
    },
    [contentType]
  );

  const refreshContent = () => loadContent(true);

  const forceRefresh = useCallback(async () => {
    // Clear the cache for this content type
    localStorage.removeItem(`${contentType}Content`);
    // Force reload from Firestore
    await loadContent(true);
  }, [contentType, loadContent]);

  return {
    content,
    setContent,
    saveContent,
    loading,
    saving,
    error,
    lastUpdated,
    refreshContent,
    forceRefresh,
  };
};
