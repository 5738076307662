import React, { useEffect, useState, useRef } from "react";

import { Mountain, TrendingDown } from "lucide-react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

// Haversine formula to calculate distance between two points
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Earth's radius in kilometers
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

const calculateRouteStatistics = (coordinates) => {
  let totalDistance = 0;
  let elevationGain = 0;
  let elevationLoss = 0;
  let maxGrade = -Infinity;
  let minGrade = Infinity;

  for (let i = 1; i < coordinates.length; i++) {
    const distance =
      calculateDistance(
        coordinates[i - 1].lat,
        coordinates[i - 1].lng,
        coordinates[i].lat,
        coordinates[i].lng
      ) * 1000; // Convert to meters

    totalDistance += distance;

    const elevationChange =
      coordinates[i].elevation - coordinates[i - 1].elevation;
    if (elevationChange > 0) {
      elevationGain += elevationChange;
    } else {
      elevationLoss += Math.abs(elevationChange);
    }

    if (distance > 0) {
      const grade = (elevationChange / distance) * 100;
      maxGrade = Math.max(maxGrade, grade);
      minGrade = Math.min(minGrade, grade);
    }
  }

  const distanceMiles = (totalDistance / 1000) * 0.621371;
  const elevationGainFeet = elevationGain * 3.28084;
  const elevationLossFeet = elevationLoss * 3.28084;

  return {
    totalDistance: Number(distanceMiles.toFixed(1)),
    totalElevationGain: Math.round(elevationGainFeet),
    totalElevationLoss: Math.round(elevationLossFeet),
    maxGrade: Number(maxGrade.toFixed(1)),
    minGrade: Number(minGrade.toFixed(1)),
    maxElevation: Math.round(
      Math.max(...coordinates.map((c) => c.elevation)) * 3.28084
    ),
    minElevation: Math.round(
      Math.min(...coordinates.map((c) => c.elevation)) * 3.28084
    ),
  };
};

const colors = {
  primaryDark: "#304b78",
  primaryLight: "#435e95",
  secondary: "#2494a2",
  accent: "#f3d678",
  backgroundDark: "#0f172a",
  backgroundLight: "#1e293b",
};

// Custom styles for the map
const mapStyles = `
  .leaflet-container {
    background: transparent !important;
  }
  
  .map-tiles {
    filter: brightness(0.95) contrast(1.1);
  }
  
  .custom-map-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      45deg,
      ${colors.primaryDark}30,
      ${colors.primaryLight}30
    );
    pointer-events: none;
  }
  
  .leaflet-control-zoom {
    border: none !important;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2) !important;
  }
  
  .leaflet-control-zoom a {
    background: ${colors.backgroundLight} !important;
    color: white !important;
    border: 1px solid rgba(255,255,255,0.1) !important;
  }
  
  .leaflet-control-zoom a:hover {
    background: ${colors.secondary} !important;
    color: white !important;
  }
  
  .leaflet-control-zoom-out.leaflet-disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
  }
  
  .leaflet-control-zoom-out.leaflet-disabled:hover {
    background: ${colors.backgroundLight} !important;
  }

  .route-marker {
    position: relative;
  }

  .route-marker::after {
    content: '';
    position: absolute;
    inset: -4px;
    border-radius: 50%;
    background: inherit;
    opacity: 0.3;
    animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  @keyframes ping {
    75%, 100% {
      transform: scale(2);
      opacity: 0;
    }
  }

  .leaflet-popup-content-wrapper,
  .leaflet-popup-tip {
    background: ${colors.backgroundLight};
    color: white;
  }
  
  /* Hide grid lines */
  .leaflet-tile-pane img {
    filter: grayscale(10%) brightness(105%);
  }
  
  /* Remove grid lines */
  .leaflet-tile {
    border: none !important;
    box-shadow: none !important;
  }
`;
const RouteVisualisation = ({
  kmlFile,
  routeName,
  routeDescription,
  keyPoints = [],
  climbs = [],
  segments = [],
  descents = [],
}) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [routeData, setRouteData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("overview");
  const mapInstanceRef = useRef(null);

  // Initialize map styles
  const initializeMapStyles = () => {
    const style = document.createElement("style");
    style.textContent = mapStyles;
    document.head.appendChild(style);
  };

  // Create custom marker function
  const createCustomMarker = (L, color, size = 16, label = "") => {
    return L.divIcon({
      className: "route-marker",
      iconSize: [size, size],
      html: `
        ${
          label
            ? `
          <div class="absolute -top-6 left-2 whitespace-nowrap text-xs font-medium bg-[${colors.backgroundLight}] text-white px-2 py-1 rounded shadow">
            ${label}
          </div>
        `
            : ""
        }
        <div class="w-full h-full rounded-full border-2 border-white shadow-lg" style="background-color: ${color}"></div>
      `,
    });
  };

  // Custom popup options
  const popupOptions = {
    className: "custom-popup",
    closeButton: false,
    maxWidth: 200,
  };

  // Create custom overlay
  const createMapOverlay = (L) => {
    return L.Layer.extend({
      onAdd: function (map) {
        this._div = L.DomUtil.create("div", "custom-map-overlay");
        map.getPanes().overlayPane.appendChild(this._div);
      },
      onRemove: function (map) {
        L.DomUtil.remove(this._div);
      },
    });
  };

  // Initialize map tiles
  const initializeMapTiles = (L, mapInstance) => {
    // Remove any existing tile layers
    mapInstance.eachLayer((layer) => {
      if (layer instanceof L.TileLayer) {
        mapInstance.removeLayer(layer);
      }
    });

    // Use the original CartoDB tile layer
    const tileLayer = L.tileLayer(
      "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
      {
        minZoom: 10, // Match the map's minZoom
        maxZoom: 20, // Match the map's maxZoom
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
        className: "map-tiles",
        subdomains: "abcd",
        crossOrigin: true,
        useCache: true,
        cacheMaxAge: 24 * 3600 * 1000,
        errorTileUrl: "",
        noWrap: true,
      }
    );

    // Add error handling
    tileLayer.on("tileerror", function (e) {
      console.error("Tile loading error:", e);
      // You might want to show a user-friendly message or try to reload the tile
    });

    return tileLayer.addTo(mapInstance);
  };

  // Add route line
  const addRouteLine = (L, mapInstance, coordinates) => {
    const routeCoords = coordinates.map((coord) => [coord.lat, coord.lng]);
    const routeLine = L.polyline(routeCoords, {
      color: colors.secondary,
      weight: 4,
      opacity: 1,
      lineCap: "round",
      lineJoin: "round",
      className: "route-line",
    }).addTo(mapInstance);

    // Set max bounds to restrict panning to the route area with some padding
    const bounds = routeLine.getBounds();
    mapInstance.setMaxBounds(bounds.pad(0.5));

    return routeLine;
  };

  // Add markers
  const addRouteMarkers = (L, mapInstance, coordinates) => {
    // Start marker
    L.marker([coordinates[0].lat, coordinates[0].lng], {
      icon: createCustomMarker(L, "#10b981", 16, "Start"),
    })
      .addTo(mapInstance)
      .bindPopup("<div class='text-sm font-medium'>Start</div>", popupOptions);

    // End marker
    L.marker(
      [
        coordinates[coordinates.length - 1].lat,
        coordinates[coordinates.length - 1].lng,
      ],
      {
        icon: createCustomMarker(L, "#ef4444", 16, "Finish"),
      }
    )
      .addTo(mapInstance)
      .bindPopup("<div class='text-sm font-medium'>Finish</div>", popupOptions);
  };

  // Main map initialization effect
  useEffect(() => {
    let mounted = true;

    const initializeMap = async () => {
      try {
        // If we already have a map instance, just update the KML data
        if (mapInstanceRef.current) {
          // Clear existing route and markers
          mapInstanceRef.current.eachLayer((layer) => {
            if (layer instanceof L.Polyline || layer instanceof L.Marker) {
              mapInstanceRef.current.removeLayer(layer);
            }
          });

          // Update with new KML data
          const storage = getStorage();
          const kmlRef = ref(storage, kmlFile);
          const kmlUrl = await getDownloadURL(kmlRef);

          // Fetch and parse new KML data
          const response = await fetch(kmlUrl, {
            mode: "cors",
            credentials: "same-origin",
            headers: {
              Accept: "application/xml",
            },
          });

          if (!response.ok) {
            throw new Error(`Failed to load KML file: ${response.statusText}`);
          }

          const kmlContent = await response.text();
          const coordinatesMatch = kmlContent.match(
            /<coordinates>([\s\S]*?)<\/coordinates>/
          );
          if (!coordinatesMatch) {
            throw new Error("No coordinates found in KML file");
          }

          // Process coordinates
          const coordinatesStr = coordinatesMatch[1].trim();
          let totalDistance = 0;
          const coordinates = coordinatesStr
            .split("\n")
            .filter((line) => line.trim())
            .map((line, index, array) => {
              const [lng, lat, elevation] = line.trim().split(",").map(Number);

              // Calculate cumulative distance
              if (index > 0) {
                const [prevLng, prevLat] = array[index - 1]
                  .trim()
                  .split(",")
                  .map(Number);
                totalDistance += calculateDistance(prevLat, prevLng, lat, lng);
              }

              return {
                lat,
                lng,
                elevation,
                distance: totalDistance,
              };
            });

          // Calculate route statistics
          const statistics = calculateRouteStatistics(coordinates);

          if (mounted) {
            setRouteData({ coordinates, statistics });
            setIsLoading(false);
          }

          return;
        }

        // Original initialization code for first load
        const L = (await import("leaflet")).default;
        initializeMapStyles();

        // Get the download URL from Firebase Storage
        const storage = getStorage();
        const kmlRef = ref(storage, kmlFile);
        const kmlUrl = await getDownloadURL(kmlRef);

        // Fetch and parse KML data using the download URL
        const response = await fetch(kmlUrl, {
          mode: "cors",
          credentials: "same-origin",
          headers: {
            Accept: "application/xml",
          },
        });
        if (!response.ok) {
          throw new Error(`Failed to load KML file: ${response.statusText}`);
        }
        const kmlContent = await response.text();

        const coordinatesMatch = kmlContent.match(
          /<coordinates>([\s\S]*?)<\/coordinates>/
        );
        if (!coordinatesMatch) {
          throw new Error("No coordinates found in KML file");
        }

        // Process coordinates
        const coordinatesStr = coordinatesMatch[1].trim();
        let totalDistance = 0;
        const coordinates = coordinatesStr
          .split("\n")
          .filter((line) => line.trim())
          .map((line, index, array) => {
            const [lng, lat, elevation] = line.trim().split(",").map(Number);

            // Calculate cumulative distance
            if (index > 0) {
              const [prevLng, prevLat] = array[index - 1]
                .trim()
                .split(",")
                .map(Number);
              totalDistance += calculateDistance(prevLat, prevLng, lat, lng);
            }

            return {
              lat,
              lng,
              elevation,
              distance: totalDistance,
            };
          });

        // Initialize map if container exists
        if (mapRef.current && !mapInstanceRef.current) {
          // Create map instance with zoom restrictions
          const mapInstance = L.map(mapRef.current, {
            center: [coordinates[0].lat, coordinates[0].lng],
            zoom: 12,
            zoomControl: false,
            attributionControl: false,
            minZoom: 10, // Limit how far users can zoom out
            maxZoom: 20, // Allow full zoom in capability
          });

          // Store instance reference
          mapInstanceRef.current = mapInstance;

          // Add controls
          L.control
            .zoom({
              position: "bottomright",
              zoomInTitle: "Zoom in",
              zoomOutTitle: "Zoom out (minimum zoom level: 10)",
            })
            .addTo(mapInstance);

          // Add event listener to show feedback when zoom limits are reached
          mapInstance.on("zoomend", function () {
            const currentZoom = mapInstance.getZoom();
            const zoomOutButton = document.querySelector(
              ".leaflet-control-zoom-out"
            );

            if (currentZoom <= 10 && zoomOutButton) {
              // Add a visual indicator that minimum zoom is reached
              zoomOutButton.classList.add("leaflet-disabled");
              zoomOutButton.style.opacity = "0.5";
              zoomOutButton.style.cursor = "not-allowed";
            } else if (zoomOutButton) {
              zoomOutButton.classList.remove("leaflet-disabled");
              zoomOutButton.style.opacity = "1";
              zoomOutButton.style.cursor = "pointer";
            }
          });

          L.control
            .attribution({
              position: "bottomleft",
            })
            .addTo(mapInstance);

          // Initialize map components
          const MapOverlay = createMapOverlay(L);
          new MapOverlay().addTo(mapInstance);
          initializeMapTiles(L, mapInstance);
          const routeLine = addRouteLine(L, mapInstance, coordinates);
          addRouteMarkers(L, mapInstance, coordinates);

          // Fit map to route bounds
          const bounds = routeLine.getBounds();
          mapInstance.fitBounds(bounds, { padding: [50, 50] });

          // Force resize for container adjustments
          setTimeout(() => {
            mapInstance.invalidateSize();
          }, 100);

          setMap(mapInstance);
        }

        // Calculate route statistics
        const statistics = calculateRouteStatistics(coordinates);

        if (mounted) {
          setRouteData({ coordinates, statistics });
          setIsLoading(false);
        }
      } catch (err) {
        console.error("Error initializing map:", err);
        if (mounted) {
          setError(err.message);
          setIsLoading(false);
        }
      }
    };

    initializeMap();

    // Cleanup function
    return () => {
      mounted = false;
      // Don't remove the map instance on unmount
      // This preserves it for future use
      // if (mapInstanceRef.current) {
      //   mapInstanceRef.current.remove();
      //   mapInstanceRef.current = null;
      // }
    };
  }, [kmlFile]); // Only re-run when kmlFile changes

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      if (mapInstanceRef.current) {
        // Add a small delay to ensure the container has resized
        setTimeout(() => {
          mapInstanceRef.current.invalidateSize();

          // Find all layers and get the route line
          const layers = Object.entries(mapInstanceRef.current._layers);
          const routeLine = layers.find(
            ([_, layer]) => layer instanceof L.Polyline
          );

          if (routeLine && routeLine[1]) {
            mapInstanceRef.current.fitBounds(routeLine[1].getBounds(), {
              padding: [20, 20],
            });
          }
        }, 250);
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    // Call once on mount to ensure proper initial sizing
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      {/* Map Container */}
      <div className="h-[50vh] min-h-[300px] max-h-[600px] relative bg-white">
        <div
          ref={mapRef}
          className="absolute inset-0"
          style={{
            zIndex: 1,
            touchAction: "manipulation",
          }}
        />
      </div>

      {/* Tab Navigation */}
      <div className="border-b border-gray-200">
        <div className="flex">
          {["overview", "segments", "descents"].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`px-6 py-3 text-sm font-medium transition-colors ${
                activeTab === tab
                  ? `border-b-2 border-[${colors.secondary}] text-[${colors.secondary}]`
                  : "text-gray-600 hover:text-gray-800"
              }`}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>
      </div>

      {/* Tab Content */}
      <div className="p-4">
        {activeTab === "overview" && routeData && (
          <div className="space-y-6">
            {/* Statistics */}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              <div>
                <div className="text-sm text-gray-600">Distance</div>
                <div className="text-lg font-semibold">
                  {routeData.statistics.totalDistance} mi
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-600">Elevation Gain</div>
                <div className="text-lg font-semibold">
                  {routeData.statistics.totalElevationGain} ft
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-600">Elevation Loss</div>
                <div className="text-lg font-semibold">
                  {routeData.statistics.totalElevationLoss} ft
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-600">Max Grade</div>
                <div className="text-lg font-semibold">
                  {routeData.statistics.maxGrade}%
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-600">Min Grade</div>
                <div className="text-lg font-semibold">
                  {routeData.statistics.minGrade}%
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-600">Max Elevation</div>
                <div className="text-lg font-semibold">
                  {Math.round(routeData.statistics.maxElevation)} ft
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-600">Min Elevation</div>
                <div className="text-lg font-semibold">
                  {Math.round(routeData.statistics.minElevation)} ft
                </div>
              </div>
            </div>

            {/* Key Climbs */}
            {climbs.length > 0 && (
              <div className="mt-6">
                <h4 className="text-lg font-semibold mb-3">Key Climbs</h4>
                <div className="space-y-3">
                  {climbs.map((climb, index) => (
                    <div key={index} className="bg-gray-50 p-3 rounded-lg">
                      <div className="flex items-center gap-2">
                        <Mountain
                          className="w-4 h-4"
                          style={{ color: colors.secondary }}
                        />
                        <span className="font-medium">{climb.name}</span>
                      </div>
                      <div className="mt-1 text-sm text-gray-600">
                        {climb.distance} | {climb.elevation} | {climb.grade}{" "}
                        avg. grade
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        {activeTab === "segments" && segments.length > 0 && (
          <div className="space-y-4">
            {segments.map((segment, index) => (
              <div key={index} className="bg-gray-50 p-4 rounded-lg">
                <div className="flex items-center gap-2 mb-2">
                  <Mountain
                    className="w-4 h-4"
                    style={{ color: colors.secondary }}
                  />
                  <h4 className="font-medium">{segment.name}</h4>
                </div>
                <div className="grid grid-cols-3 gap-4 text-sm">
                  <div>
                    <span className="text-gray-600">Distance:</span>
                    <span className="ml-2 text-gray-800">
                      {segment.distance}
                    </span>
                  </div>
                  <div>
                    <span className="text-gray-600">Elevation:</span>
                    <span className="ml-2 text-gray-800">
                      {segment.elevation}
                    </span>
                  </div>
                  <div>
                    <span className="text-gray-600">Grade:</span>
                    <span className="ml-2 text-gray-800">{segment.grade}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {activeTab === "descents" && descents.length > 0 && (
          <div className="space-y-4">
            {descents.map((descent, index) => (
              <div key={index} className="bg-gray-50 p-4 rounded-lg">
                <div className="flex items-center gap-2 mb-2">
                  <TrendingDown
                    className="w-4 h-4"
                    style={{ color: colors.secondary }}
                  />
                  <div className="flex-1">
                    <h4 className="font-medium">Descent {index + 1}</h4>
                    <p className="text-sm text-gray-600">
                      {descent.distance} • {descent.elevation} • {descent.grade}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default RouteVisualisation;
