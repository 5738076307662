import React, { useState, useEffect, useRef } from "react";
import { motion, LayoutGroup, AnimatePresence } from "framer-motion";
import {
  ChevronDown,
  Calendar,
  MapPin,
  ExternalLink,
  ArrowDown,
  Play,
} from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import RouteVisualisation from "../Components/RouteVisualisation";
import { useContent } from "../hooks/useContent";
import { useSeoSettings } from "../hooks/useSeoSettings";
import { Helmet } from "react-helmet-async";
import EventbriteWidget from "../Components/EventbriteWidget";
import RegisterInterestModal from "../Components/RegisterInterestModal";
import RegistrationButton from "../Components/RegistrationButton";
import { preloadMedia, getMediaFromCache } from "../services/contentCache";

const colors = {
  primaryDark: "#304b78",
  primaryLight: "#435e95",
  secondary: "#2494a2",
  accent: "#f3d678",
  backgroundDark: "#0f172a",
  backgroundLight: "#1e293b",
};

const EventSection = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isVideoRevealed, setIsVideoRevealed] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const videoRef = useRef(null);
  const { content, loading } = useContent("about");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { content: siteConfig } = useContent("siteConfig");
  const [videoSrc, setVideoSrc] = useState(null);
  const navigate = useNavigate();

  // Effect to cache video on first load
  useEffect(() => {
    if (content?.hero?.videoUrl) {
      const videoUrl = content.hero.videoUrl;

      // Preload the video to ensure it's cached
      preloadMedia(videoUrl);

      // Set up the video URL with appropriate parameters
      const params = new URLSearchParams({
        controls: "1",
        autoplay: "0",
        playsinline: "1",
        rel: "0", // Don't show related videos
        modestbranding: "1", // Remove YouTube logo from controls
        origin: window.location.origin,
      });

      // Create the full URL with parameters
      setVideoSrc(`${videoUrl}?${params.toString()}`);
    }
  }, [content?.hero?.videoUrl]);

  // Helper function to handle registration button clicks
  const handleRegisterClick = () => {
    // Check if modal is disabled by settings
    const isModalDisabled = siteConfig?.disableRegistrationModal;

    if (isModalDisabled && siteConfig?.defaultRegistrationUrl) {
      // If modal is disabled and we have a default URL, use it
      if (siteConfig.defaultRegistrationUrl.startsWith("http")) {
        // Check if it's an internal URL (contains our domain)
        if (siteConfig.defaultRegistrationUrl.includes("tourde4.com")) {
          // Extract the path from the URL
          const url = new URL(siteConfig.defaultRegistrationUrl);
          // Use the pathname and hash for internal navigation
          navigate(url.pathname + url.hash);
        } else {
          // External URL - open in new window
          window.open(siteConfig.defaultRegistrationUrl, "_blank");
        }
      } else {
        // Already a relative path
        navigate(siteConfig.defaultRegistrationUrl);
      }
    } else {
      // Otherwise show the modal
      setIsModalOpen(true);
    }
  };

  // Helper function to load and play video
  const loadAndPlayVideo = () => {
    if (!videoLoaded) {
      setIsLoading(true);
      setVideoLoaded(true);
      // We'll reset the loading state after a short delay to simulate the iframe loading
      setTimeout(() => {
        setIsLoading(false);
        setIsPlaying(true);
      }, 1500);
    } else if (videoRef.current) {
      setIsPlaying(true);
    }
  };

  // Handle scroll reveal on mobile for event section
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 768) {
        const eventSection = document.getElementById("event");
        if (!eventSection) return;

        const rect = eventSection.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        // Only reveal when the section is 75% in view (instead of 50%)
        if (rect.top < viewportHeight * 0.25) {
          setIsVideoRevealed(true);
        } else {
          setIsVideoRevealed(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleVideoReveal = (reveal) => {
    setIsVideoRevealed(reveal);
    // Load the video when it's revealed for the first time
    if (reveal && !videoLoaded) {
      setVideoLoaded(true);
    }
  };

  const handleOverlayClick = () => {
    if (window.innerWidth <= 768) {
      const newRevealState = !isVideoRevealed;
      setIsVideoRevealed(newRevealState);
      // Load the video when it's revealed for the first time
      if (newRevealState) {
        loadAndPlayVideo();
      }
    }
  };

  const handlePlaceholderClick = () => {
    loadAndPlayVideo();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const eventOptions = content?.event?.eventOptions || [];

  return (
    <section className="bg-gray-50 py-20">
      <div className="max-w-6xl mx-auto px-6">
        <div className="grid lg:grid-cols-2 gap-12 items-start mb-12">
          <div className="space-y-2">
            <h2
              className="text-4xl font-bold"
              style={{ color: colors.primaryDark }}
            >
              The Event
            </h2>
            <p className="text-gray-600 text-lg">
              Starting and finishing at the Sir Chris Hoy Velodrome on Sunday
              7th September 2025, Tour de 4 offers 4 different rides, so you can
              pick a route that best suits your ability.
            </p>
          </div>

          {/* Video Section */}
          {content?.hero?.videoUrl && (
            <div className="relative">
              <motion.div
                className="absolute inset-0 bg-gradient-to-r from-secondary/20 to-accent/20 rounded-2xl transform -rotate-2"
                initial={{ opacity: 0 }}
                animate={{ opacity: isVideoRevealed ? 1 : 0 }}
                transition={{ duration: 0.5 }}
              />
              <div className="relative">
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.3 }}
                  className="relative w-full overflow-hidden"
                  onMouseEnter={() => {
                    if (window.innerWidth > 768) handleVideoReveal(true);
                  }}
                  onMouseLeave={() => {
                    if (window.innerWidth > 768) handleVideoReveal(false);
                  }}
                >
                  {/* Video content */}
                  <motion.div
                    className="relative aspect-video w-full"
                    animate={{
                      borderRadius: isVideoRevealed ? "0.5rem" : "0rem",
                    }}
                    transition={{ duration: 0.5 }}
                  >
                    {content?.hero?.videoUrl &&
                    content?.hero?.showVideo !== false ? (
                      <>
                        {videoLoaded ? (
                          <>
                            <motion.iframe
                              src={
                                videoSrc
                                  ? `${videoSrc}${
                                      isPlaying ? "&autoplay=1" : ""
                                    }`
                                  : ""
                              }
                              title="Tour De 4 Routes"
                              className="w-full h-full absolute inset-0"
                              animate={{
                                borderRadius: isVideoRevealed
                                  ? "0.5rem"
                                  : "0rem",
                                opacity: isLoading ? 0 : 1,
                              }}
                              transition={{ duration: 0.5 }}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              frameBorder="0"
                              loading="lazy"
                              style={{
                                pointerEvents: isVideoRevealed
                                  ? "auto"
                                  : "none",
                                zIndex: 2,
                              }}
                              ref={videoRef}
                              onLoad={() => setIsLoading(false)}
                              data-cacheable="true"
                              importance="high"
                              fetchpriority="high"
                            />

                            {/* Loading indicator overlay */}
                            {isLoading && (
                              <div
                                className="absolute inset-0 flex items-center justify-center bg-gray-100"
                                style={{
                                  zIndex: 3,
                                  borderRadius: isVideoRevealed
                                    ? "0.5rem"
                                    : "0rem",
                                }}
                              >
                                <div className="text-center">
                                  <svg
                                    className="animate-spin h-10 w-10 text-secondary mx-auto mb-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                  <p className="text-gray-600">
                                    Loading video...
                                  </p>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div
                            className="w-full h-full absolute inset-0 bg-gray-100 flex items-center justify-center cursor-pointer"
                            style={{
                              zIndex: 2,
                              borderRadius: isVideoRevealed ? "0.5rem" : "0rem",
                            }}
                            onClick={handlePlaceholderClick}
                          >
                            {/* Placeholder with play button */}
                            <div className="text-center relative">
                              <img
                                src="/TD4-colour.webp"
                                alt="Tour De 4 Logo"
                                className="w-32 h-auto mx-auto mb-4"
                              />
                              <div className="w-16 h-16 rounded-full bg-secondary text-white flex items-center justify-center mx-auto mb-2 shadow-lg hover:bg-opacity-90 transition-all">
                                <Play
                                  className="w-8 h-8"
                                  fill="white"
                                  strokeWidth={0.5}
                                />
                              </div>
                              <p className="text-gray-600">
                                Click to load and play video
                              </p>
                            </div>
                          </div>
                        )}
                        <motion.div
                          className={`absolute inset-0 w-full h-full transition-opacity duration-500 cursor-pointer ${
                            isVideoRevealed
                              ? "opacity-0 pointer-events-none"
                              : "opacity-100"
                          }`}
                          style={{ zIndex: 3 }}
                          onClick={handleOverlayClick}
                        >
                          <motion.svg
                            viewBox="0 0 16 9"
                            preserveAspectRatio="xMidYMid meet"
                            style={{
                              width: "100%",
                              height: "100%",
                              transform: "scale(1.1)",
                              transformOrigin: "center",
                              WebkitTransform: "scale(1.1)",
                            }}
                          >
                            <defs>
                              <mask id="logoMask" maskUnits="userSpaceOnUse">
                                <rect width="100%" height="100%" fill="black" />
                                <image
                                  href="/TD4-reverse.svg"
                                  width="100%"
                                  height="100%"
                                  preserveAspectRatio="xMidYMid meet"
                                  style={{
                                    filter:
                                      "contrast(1) brightness(0) invert(1)",
                                    WebkitFilter:
                                      "contrast(1) brightness(0) invert(1)",
                                  }}
                                />
                              </mask>
                            </defs>
                            <rect
                              width="100%"
                              height="100%"
                              fill="#f9fafb"
                              mask="url(#logoMask)"
                            />
                          </motion.svg>
                        </motion.div>
                      </>
                    ) : (
                      <div className="w-full h-full flex items-center justify-center">
                        <img
                          src="/TD4-colour.webp"
                          alt="Tour De 4 Logo"
                          className="w-48 h-auto"
                        />
                      </div>
                    )}
                  </motion.div>
                </motion.div>
              </div>
            </div>
          )}
        </div>

        <div className="grid lg:grid-cols-3 gap-8">
          {/* Event Options */}
          <div className="lg:col-span-2 space-y-4">
            <h3
              className="text-2xl font-semibold"
              style={{ color: colors.secondary }}
            >
              Event Options
            </h3>

            <LayoutGroup>
              {eventOptions.map((option, index) => (
                <motion.div
                  key={option.title}
                  layout
                  className="rounded-lg shadow-sm overflow-hidden bg-white"
                  transition={{
                    layout: { duration: 0.2, ease: "easeOut" },
                  }}
                >
                  <motion.div
                    className="p-4 cursor-pointer"
                    onClick={() =>
                      setSelectedEvent(selectedEvent === index ? null : index)
                    }
                  >
                    <div className="flex justify-between items-center">
                      <h4 className="font-semibold text-lg">{option.title}</h4>
                      <motion.div
                        animate={{ rotate: selectedEvent === index ? 180 : 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <ChevronDown className="w-5 h-5 text-gray-500" />
                      </motion.div>
                    </div>
                    <p className="text-gray-600">{option.description}</p>
                  </motion.div>

                  {selectedEvent === index && (
                    <motion.div
                      layout
                      className="border-t border-gray-100 p-4 bg-white"
                      initial={false}
                      animate={{
                        height: selectedEvent === index ? "auto" : 0,
                        opacity: selectedEvent === index ? 1 : 0,
                      }}
                      transition={{ duration: 0.3 }}
                      style={{ overflow: "hidden" }}
                    >
                      <div className="grid gap-4">
                        {Object.entries(option.details).map(
                          ([key, value]) =>
                            value && (
                              <div
                                key={key}
                                className={`${
                                  key === "entryFee" ? "mt-2 mb-2" : ""
                                } flex justify-between`}
                              >
                                {key === "entryFee" ? (
                                  <>
                                    <div className="w-full bg-gray-50 p-3 rounded-lg border border-gray-200 flex justify-between items-center">
                                      <span className="font-semibold text-gray-700">
                                        Entry Fee:
                                      </span>
                                      <span
                                        className="px-3 py-1 rounded-full text-white font-medium"
                                        style={{ background: colors.secondary }}
                                      >
                                        {option.routeConfig?.isComingSoon
                                          ? "TBA"
                                          : value}
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <span
                                      className="font-medium capitalize text-gray-700"
                                      style={{ color: colors.secondary }}
                                    >
                                      {key.replace(/([A-Z])/g, " $1").trim()}:
                                    </span>
                                    <span className="text-gray-600">
                                      {option.routeConfig?.isComingSoon
                                        ? "TBA"
                                        : value}
                                    </span>
                                  </>
                                )}
                              </div>
                            )
                        )}

                        {option.routeConfig && (
                          <div
                            className="mt-6 mb-4"
                            style={{
                              display:
                                selectedEvent === index ? "block" : "none",
                              position: "relative",
                              minHeight: selectedEvent === index ? "auto" : 0,
                            }}
                          >
                            {option.routeConfig.isComingSoon ? (
                              <div className="relative w-full aspect-video">
                                <img
                                  src={option.routeConfig.placeholderImage}
                                  alt="Coming Soon"
                                  className="w-full h-full object-cover rounded-lg"
                                />
                                <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center rounded-lg">
                                  <h3 className="text-3xl font-bold text-white mb-4">
                                    Coming Soon
                                  </h3>
                                  <img
                                    src="/TD4-colour.webp"
                                    alt="Tour De 4 Logo"
                                    className="w-32 h-auto"
                                  />
                                </div>
                              </div>
                            ) : option.routeConfig.type === "static" ? (
                              <div className="space-y-4">
                                {option.routeConfig.routeImage && (
                                  <img
                                    src={option.routeConfig.routeImage}
                                    alt={option.routeConfig.routeName}
                                    className="w-full rounded-lg shadow-sm"
                                    loading="lazy"
                                  />
                                )}
                                <div className="prose prose-sm">
                                  <p className="text-gray-700">
                                    {option.routeConfig.routeDescription}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  opacity: selectedEvent === index ? 1 : 0,
                                }}
                              >
                                <RouteVisualisation {...option.routeConfig} />
                              </div>
                            )}
                          </div>
                        )}

                        {!option.routeConfig?.isComingSoon &&
                          (option.routeConfig?.eventbriteId ? (
                            <div className="mt-4">
                              <EventbriteWidget
                                eventId={option.routeConfig.eventbriteId}
                                buttonText={`Register for ${option.title}`}
                                buttonClassName="w-full px-6 py-3 rounded-full text-white font-semibold transition-transform hover:scale-[1.02] active:scale-[0.98]"
                                style={{ background: colors.secondary }}
                                onRegisterInterest={handleRegisterClick}
                                isSoldOut={option.routeConfig?.isSoldOut}
                                soldOutText="This Wave Sold Out"
                              />
                            </div>
                          ) : (
                            <button
                              onClick={handleRegisterClick}
                              className="mt-4 w-full px-6 py-3 rounded-full text-white font-semibold transition-transform hover:scale-[1.02] active:scale-[0.98]"
                              style={
                                option.routeConfig?.isSoldOut
                                  ? {
                                      background: "#9ca3af",
                                      cursor: "not-allowed",
                                      opacity: 0.7,
                                    }
                                  : { background: colors.secondary }
                              }
                              disabled={option.routeConfig?.isSoldOut}
                            >
                              {option.routeConfig?.isSoldOut
                                ? "This Wave Sold Out"
                                : `Register for ${option.title}`}
                            </button>
                          ))}
                      </div>
                    </motion.div>
                  )}
                </motion.div>
              ))}
            </LayoutGroup>
          </div>

          {/* Key Information */}
          <div className="space-y-6">
            <h3
              className="text-2xl font-semibold"
              style={{ color: colors.secondary }}
            >
              Key Information
            </h3>
            <div className="space-y-4">
              <div className="p-6 rounded-lg bg-white shadow-sm transition-transform duration-200 hover:-translate-y-1">
                <div className="flex items-center gap-3 mb-3">
                  <Calendar
                    className="w-5 h-5"
                    style={{ color: colors.secondary }}
                  />
                  <h4 className="font-semibold text-lg">Schedule</h4>
                </div>
                <div className="space-y-2">
                  <p className="text-gray-600">
                    Event Date - Sunday 7th September, 2025
                  </p>
                  <p className="text-gray-500 text-sm">
                    Sign up for updates to be notified
                  </p>
                </div>
              </div>

              <div className="p-6 rounded-lg bg-white shadow-sm transition-transform duration-200 hover:-translate-y-1">
                <div className="flex items-center gap-3 mb-3">
                  <MapPin
                    className="w-5 h-5"
                    style={{ color: colors.secondary }}
                  />
                  <h4 className="font-semibold text-lg">Location</h4>
                </div>
                <div className="space-y-2">
                  <p className="text-gray-600">
                    Sir Chris Hoy Velodrome, Glasgow, Scotland
                  </p>
                  <a
                    href="/resources#travel"
                    className="inline-flex items-center gap-2 mt-2 text-sm font-medium transition-transform duration-200 hover:translate-x-1"
                    style={{ color: colors.secondary }}
                  >
                    View transport information
                    <ChevronDown className="w-4 h-4 rotate-[-90deg]" />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="p-4 rounded-lg"
              style={{ background: colors.primaryDark }}
            >
              <p className="text-white text-sm">
                For the latest updates and announcements about the event, follow
                us on social media.
              </p>
            </div>
          </div>
        </div>
      </div>

      <RegisterInterestModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        source="about"
      />
    </section>
  );
};

const AboutPage = () => {
  const { content, loading } = useContent("about");
  const { seoSettings } = useSeoSettings();
  const [isVideoRevealed, setIsVideoRevealed] = useState(false);
  const navigate = useNavigate();

  // Handle scroll reveal on mobile
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 768) {
        // Mobile breakpoint
        const scrollPosition = window.scrollY;
        if (scrollPosition > 100) {
          // Adjust this value as needed
          setIsVideoRevealed(true);
        } else {
          setIsVideoRevealed(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-slate-800 flex items-center justify-center">
        <div className="text-white">Loading...</div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {seoSettings?.pages?.about?.title ||
            "About Tour De 4 - Our Mission & Vision"}
        </title>
        <meta
          name="description"
          content={
            seoSettings?.pages?.about?.description ||
            "Learn about Tour De 4's mission to support those living with Stage 4 cancer and our vision for creating positive change through cycling."
          }
        />
        <meta name="theme-color" content={seoSettings?.metaTags?.themeColor} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoSettings?.openGraph?.url} />
        <meta
          property="og:title"
          content={
            seoSettings?.pages?.about?.title || seoSettings?.openGraph?.title
          }
        />
        <meta
          property="og:description"
          content={
            seoSettings?.pages?.about?.description ||
            seoSettings?.openGraph?.description
          }
        />
        <meta
          property="og:image"
          content={
            seoSettings?.pages?.about?.image || seoSettings?.openGraph?.image
          }
        />

        {/* Twitter */}
        <meta property="twitter:card" content={seoSettings?.twitter?.card} />
        <meta property="twitter:url" content={seoSettings?.openGraph?.url} />
        <meta
          property="twitter:title"
          content={
            seoSettings?.pages?.about?.title || seoSettings?.twitter?.title
          }
        />
        <meta
          property="twitter:description"
          content={
            seoSettings?.pages?.about?.description ||
            seoSettings?.twitter?.description
          }
        />
        <meta
          property="twitter:image"
          content={
            seoSettings?.pages?.about?.image || seoSettings?.twitter?.image
          }
        />
      </Helmet>

      <div className="min-h-screen">
        {/* Hero Section */}
        <motion.div
          className="min-h-[50vh] flex items-center justify-center relative overflow-hidden"
          style={{
            background: `linear-gradient(to bottom right, ${colors.backgroundDark}, ${colors.backgroundLight})`,
          }}
        >
          {/* Decorative Background Element */}
          <motion.div
            className="absolute top-20 left-20 w-96 h-96 rounded-full opacity-10"
            style={{
              background: colors.secondary,
              filter: "blur(60px)",
            }}
            animate={{
              scale: [1, 1.2, 1],
              rotate: [0, 90, 0],
            }}
            transition={{
              duration: 20,
              ease: "linear",
              repeat: Infinity,
            }}
          />

          <div className="max-w-7xl mx-auto px-8 text-center relative z-10">
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.4, delay: 0.2 }}
              className="space-y-8"
            >
              <div className="space-y-4">
                <motion.h1
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  className="text-5xl md:text-6xl font-bold text-white"
                >
                  {content?.hero?.title || "About"}
                </motion.h1>
                {content?.hero?.subtitle && (
                  <motion.p
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.1 }}
                    className="text-2xl text-white/70 max-w-3xl mx-auto"
                  >
                    {content.hero.subtitle}
                  </motion.p>
                )}
              </div>

              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
                className="flex flex-wrap gap-4 justify-center pt-6"
              >
                <button
                  onClick={() => scrollToSection("mission")}
                  className="px-8 py-3 rounded-full text-white font-semibold transition-all hover:opacity-90"
                  style={{ background: colors.secondary }}
                >
                  The Mission
                </button>
                <button
                  onClick={() => scrollToSection("event")}
                  className="px-8 py-3 rounded-full text-slate-800 font-semibold transition-all hover:opacity-90"
                  style={{ background: colors.accent }}
                >
                  The Event
                </button>
              </motion.div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.8 }}
              className="mt-12"
            >
              <ArrowDown className="w-8 h-8 text-white/50 mx-auto animate-bounce" />
            </motion.div>
          </div>
        </motion.div>

        {/* Mission Section */}
        <motion.section
          id="mission"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="bg-white py-20"
        >
          <div className="max-w-6xl mx-auto px-6 space-y-12">
            <h2
              className="text-4xl font-bold"
              style={{ color: colors.primaryDark }}
            >
              {content?.mission?.title || "The Mission"}
            </h2>

            <div className="space-y-8">
              <div className="prose prose-lg">
                <p className="text-lg text-gray-700">
                  {content?.mission?.description}
                </p>

                <div className="my-8 p-6 rounded-lg bg-gray-50">
                  <h3
                    className="text-xl font-semibold mb-4"
                    style={{ color: colors.secondary }}
                  >
                    Key Statistics
                  </h3>
                  <ul className="space-y-2 text-gray-700">
                    {content?.mission?.statistics?.map((stat, index) => (
                      <li key={index}>
                        {stat.value} {stat.description}
                      </li>
                    ))}
                  </ul>
                </div>

                <p className="text-lg text-gray-700">
                  {content?.mission?.vision}
                </p>
              </div>

              {content?.mission?.donationLink && (
                <motion.a
                  href={content.mission.donationLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center gap-2 px-6 py-3 rounded-full text-white font-semibold transition-all"
                  style={{ background: colors.secondary }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Make a Donation <ExternalLink size={16} />
                </motion.a>
              )}
            </div>
          </div>
        </motion.section>

        {/* Event Section */}
        <motion.section id="event">
          <EventSection />
        </motion.section>
      </div>
    </>
  );
};

export default AboutPage;
