import { getRouteProtectionSettings } from "../firebase/routeProtections";

// Global cache for all content
const contentCache = {
  settings: null,
  content: {},
  media: {}, // Special cache for media content
  lastUpdate: null,
};

// Cache TTL values
const CACHE_TTL = {
  DEFAULT: 24 * 60 * 60 * 1000, // 24 hours
  MEDIA: 30 * 24 * 60 * 60 * 1000, // 30 days for media
};

// Check if cache is stale (older than TTL or doesn't exist)
const isCacheStale = (type = "content") => {
  if (!contentCache.lastUpdate) return true;
  const now = Date.now();
  const cacheAge = now - contentCache.lastUpdate;
  return type === "media"
    ? cacheAge > CACHE_TTL.MEDIA
    : cacheAge > CACHE_TTL.DEFAULT;
};

// Initialize cache with content
export const initializeCache = async () => {
  try {
    // Fetch route settings
    const settings = await getRouteProtectionSettings();
    contentCache.settings = settings;

    // Here you would fetch all other content needed for the site
    // For example:
    // const homeContent = await fetchHomeContent();
    // const aboutContent = await fetchAboutContent();
    // etc...

    contentCache.lastUpdate = Date.now();

    // Store in localStorage as backup
    localStorage.setItem("siteContent", JSON.stringify(contentCache));

    return true;
  } catch (error) {
    console.error("Failed to initialize cache:", error);
    return false;
  }
};

// Get content from cache
export const getFromCache = (key) => {
  return contentCache.content[key];
};

// Store content in cache
export const storeInCache = (key, data) => {
  contentCache.content[key] = data;
  localStorage.setItem("siteContent", JSON.stringify(contentCache));
  return data;
};

// Cache media content (videos, large images, etc)
export const cacheMedia = (url, data) => {
  contentCache.media[url] = {
    data,
    timestamp: Date.now(),
  };

  // We don't store media in localStorage to avoid size issues
  // Instead, we rely on the service worker cache

  return data;
};

// Get media from cache if available
export const getMediaFromCache = (url) => {
  const cachedMedia = contentCache.media[url];
  if (!cachedMedia) return null;

  const now = Date.now();
  const cacheAge = now - cachedMedia.timestamp;

  // Check if media cache is stale
  if (cacheAge > CACHE_TTL.MEDIA) {
    delete contentCache.media[url];
    return null;
  }

  return cachedMedia.data;
};

// Preload important media (call this for videos that you want to preload)
export const preloadMedia = async (url) => {
  // Check if already in cache
  if (getMediaFromCache(url)) return;

  try {
    // For videos we just perform a HEAD request to trigger caching in service worker
    // We don't actually load the whole video in memory
    const response = await fetch(url, { method: "HEAD" });

    if (response.ok) {
      // Mark this URL as cached
      cacheMedia(url, {
        cached: true,
        size: response.headers.get("content-length") || "unknown",
      });
    }
  } catch (error) {
    console.error("Failed to preload media:", error);
  }
};

// Force refresh cache
export const refreshCache = async () => {
  contentCache.content = {};
  contentCache.settings = null;
  contentCache.lastUpdate = null;
  localStorage.removeItem("siteContent");
  return initializeCache();
};

// Refresh only media cache
export const refreshMediaCache = () => {
  contentCache.media = {};
};

// Get settings from cache
export const getSettingsFromCache = () => {
  if (contentCache.settings) {
    return contentCache.settings;
  }

  // Try localStorage backup
  const storedCache = localStorage.getItem("siteContent");
  if (storedCache) {
    const parsed = JSON.parse(storedCache);
    if (parsed.settings) {
      contentCache.settings = parsed.settings;
      return parsed.settings;
    }
  }

  return null;
};

// Update cache (called after admin panel updates)
export const updateCache = async (newContent) => {
  contentCache.content = {
    ...contentCache.content,
    ...newContent,
  };
  contentCache.lastUpdate = Date.now();

  // Update localStorage
  localStorage.setItem("siteContent", JSON.stringify(contentCache));
};
